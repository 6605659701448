import React, {FC, ReactNode, useEffect, useState} from "react";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {IMenuList, IState} from "../../types/state";
import {IFooterAccount as IProps} from "./FooterNewsLetter/types";
import {useDispatch, useSelector} from "react-redux";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {apiImageUrl} from "../../helper";
import {setWindowSize} from "../../store/general";
import {rightUrl} from "../../services/utils";
import {useRouter} from "next/router";

const FooterAccount: FC<IProps> = ({pageProps}) => {
    const router = useRouter();
    const footerLinks = pageProps?.menus?.filter((item: { type: string }) => item.type === "footer")
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)

    return (
        <>
            <div className="header__logo">
                {
                    domain ? (
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <LazyLoadImage
                                        alt="mobile-logo"
                                        height={45}
                                        src={`/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`}
                                        onError={(e) => {
                                            // @ts-ignore

                                            e.target.src = `${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg?v=${cacheVersion}`;
                                        }}
                                    />
                                </a>
                            </Link>
                        )
                        : (
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <LazyLoadImage
                                        alt=""
                                        width={150}
                                        height={45}
                                        src={`${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg`}
                                    />
                                </a>
                            </Link>
                        )
                }
            </div>

            <div className="footer_text">
                <p>
                    <FormattedMessage
                        id="footer.text"
                        defaultMessage="Lorem ipsum dolor sit amet consectetur. Proin nunc proin et sed. "
                    />
                </p>

            </div>

            {pageProps?.isContactActive && <div className="trackingNumber-fms">
                <Link href={'/contact'}>
                    <a>
                        <FormattedMessage
                            //TODO this pageProps doesn't have any hasTracking key, it is undefined
                            id="contact_with_us"
                            defaultMessage="Contact With Us"
                        />
                    </a>
                </Link>
            </div>}
        </>
    );
}

export default FooterAccount;
